@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--muted-background: 0 0% 96.1%;
		--foreground: 0 0% 3.9%;
		--muted: 0 0% 96.1%;
		--muted-foreground: 0 0% 45.1%;
		--popover: 0 0% 100%;
		--popover-foreground: 0 0% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 0 0% 3.9%;
		--border: 0 0% 89.8%;
		--input: 0 0% 89.8%;
		--primary: 0 0% 9%;
		--ao-blue: 217 90% 54%;
		--primary-foreground: 0 0% 98%;
		--secondary: 0 0% 96.1%;
		--secondary-foreground: 0 0% 9%;
		--accent: 0 0% 96.1%;
		--accent-foreground: 0 0% 9%;
		--destructive: 0 84.2% 60.2%;
		--highlight: 217 91% 60%;
		--highlight-foreground: 0 0% 98%;
		--destructive-foreground: 0 0% 98%;
		--ring: 0 0% 63.9%;
		--radius: 0.5rem;
	}
	.dark {
		--background: 0 0% 3.9%;
		--muted-background: 0 0% 96.1%;
		--foreground: 0 0% 98%;
		--muted: 0 0% 14.9%;
		--muted-foreground: 0 0% 63.9%;
		--popover: 0 0% 3.9%;
		--popover-foreground: 0 0% 98%;
		--card: 0deg 0% 14.9% / 13%;
		--card-foreground: 0 0% 98%;
		--border: 0 0% 14.9%;
		--input: 0 0% 14.9%;
		--primary: 0 0% 98%;
		--ao-blue: 217 90% 54%;
		--primary-foreground: 0 0% 9%;
		--secondary: 0 0% 14.9%;
		--secondary-foreground: 0 0% 98%;
		--accent: 0 0% 14.9%;
		--accent-foreground: 0 0% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 85.7% 97.3%;
		--highlight: 217 91% 60%;
		--highlight-foreground: 0 0% 98%;
		--ring: 0 0% 14.9%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}

.discord-message a {
	@apply text-blue-700 hover:underline dark:text-blue-400 !important;
}

html {
	margin: 0;
	width: 100%;
}

body {
	margin: 0;
	width: 100%;
}

.dark {
	color-scheme: dark;
}

/* Speculative fix for lacking of loading of images on mobile in a marquee */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
	img[loading="lazy"] {
		clip-path: inset(0.6px);
	}
}

.prose pre:not([class]) {
	@apply bg-transparent p-0 !important;
	min-width: max-content;
}

.discord-message a {
	@apply text-blue-700 hover:underline dark:text-blue-400 !important;
}

.d-emoji {
	@apply inline-block h-6 w-6;
}

.heading-1 {
	@apply text-4xl font-bold !important;
}

.heading-2 {
	@apply text-3xl font-bold !important;
}

.heading-3 {
	@apply text-2xl font-bold !important;
}

.heading-4 {
	@apply text-xl font-bold !important;
}

.heading-5 {
	@apply text-lg font-bold !important;
}

.heading-6 {
	@apply text-base font-bold !important;
}

.emoji {
	@apply inline-block h-6 w-6;
}

img:not([src]):is(:empty) {
	@apply h-0 w-0 !important;
}

.discord-message {
	white-space: break-spaces;
	line-height: 22px;
}

* {
	min-width: 0;
}

@layer components {
	.spinner-border {
		border-top-color: transparent;
		border-right-color: #3490dc; /* Change this color as needed */
		border-bottom-color: #3490dc; /* Change this color as needed */
		border-left-color: #3490dc; /* Change this color as needed */
	}
}
